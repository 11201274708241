.change-password-main-container {
  background-color: var(--color-surface-primary);
  height: 100vh;

  .email-password-main-container {
    padding: 20px 0px;
    background-color: var(--color-surface-primary);

    .edge-logo {
      height: 47px;
      width: 91px;
      object-fit: cover;
    }

    .change-password-inner-container {
      width: 440px;
      max-width: 440px;

      .subheader-text {
        color: var(--color-text-body-primary);
      }

      .label-color {
        color: var(--color-text-headings-secondary);
      }

      .icon-color {
        color: var(--color-icon-secondary);
      }

      .email-container {
        display: flex;
        padding: 12px 16px;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: var(--border-radius-sm, 4px);
        background: var(--color-surface-primary-hover);

        .email-icon-bg {
          display: flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          border-radius: 40px;
          background: var(--color-surface-primary);
        }

        .email-color {
          color: var(--color-text-body-secondary);
        }
      }
    }
  }

  .slider-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, #f6f6ff 0%, #ddf 100%);

    @media (max-width: 768px) {
      padding-top: 32px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

@media (max-width: 768px) {
  .change-password-main-container {
    overflow-y: scroll;

    .email-password-main-container {
      padding: 20px;

      .change-password-inner-container {
        width: 100%;
      }
    }
  }
}
