@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

// Shared styles (keep these global)
:root {
  --font-family-inter: Inter, sans-serif;
  --color-text-primary: #000000de;
  --color-background-disabled: #ededed;
  --color-text-disabled: #999999;
  --color-text-headings-secondary: #7b7b7b;
  --color-border-primary: #dfdfdf;
  --color-border-primary-hover: var(--color-primitives-primary-600);
  --color-border-focused: #353ba8;
  --color-border-error: #e3382d;
  --color-text-error: #952019;
}

%font-styles {
  font-family: var(--font-family-inter) !important;
}

.paragraph,
.heading,
.overline {
  @extend %font-styles;
}

@mixin font-weight($weight) {
  font-weight: var(--font-weight-#{$weight}) !important;
}

@mixin font-size($size) {
  font-size: var(--font-size-#{$size}) !important;
}

@mixin line-height($size) {
  line-height: var(--line-height-#{$size}) !important;
}

.font {
  &-regular {
    @include font-weight(regular);
  }
  &-medium {
    @include font-weight(medium);
  }
  &-semibold {
    @include font-weight(semibold);
  }
  &-bold {
    @include font-weight(bold);
  }
}

.text {
  &-xs {
    @include font-size(xs);
  }
  &-sm {
    @include font-size(sm);
  }
  &-md {
    @include font-size(md);
  }
  &-lg {
    @include font-size(lg);
  }
  &-xl {
    @include font-size(xl);
  }
  &-xxl {
    @include font-size(2xl);
  }
}

.line-height {
  &-sm {
    @include line-height(sm);
  }
  &-md {
    @include line-height(md);
  }
  &-lg {
    @include line-height(lg);
  }
  &-xl {
    @include line-height(xl);
  }
  &-xxl {
    @include line-height(xxl);
  }
  &-xxxl {
    @include line-height(xxxl);
  }
}

.error-text-color {
  color: var(--color-primitives-danger-600) !important;
}

.non-transform {
  transform: none !important;
}

.pos-relative {
  position: relative !important;
}

.hidden-placeholder {
  display: none !important;
}

.ant-text-component {
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .select-divider {
    margin: 8px 0;
  }

  .placeholder {
    font-family: var(--font-family-inter) !important;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-xl);
    color: var(--color-text-headings-secondary) !important;
    opacity: 0.38 !important;
  }

  .custom-text-input-root {
    min-width: 120px !important;
  }

  .text-field {
    &-padding {
      padding-inline: 12px !important;
    }

    &-input {
      &-root {
        height: auto !important;
        border-radius: 4px !important;

        .MuiInputBase-input {
          padding: 0px !important;
        }
      }

      &-small {
        padding-block: 8px !important;
      }

      &-medium {
        padding-block: 16px !important;
      }

      &-multiline {
        padding: 0 !important;

        &-root {
          align-items: flex-start !important;

          textarea {
            padding: 0 !important;
          }
        }
      }
    }
  }

  .custom-text-input {
    &-root {
      @extend .text-field-input-root;
    }

    &-focused .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-border-focused) !important;
    }

    &-error .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-border-error) !important;
    }
  }

  .MuiOutlinedInput-root {
    &.Mui-disabled {
      background-color: var(--color-background-disabled);

      fieldset {
        border: none;
      }
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-border-focused);
    }
  }

  .MuiInputBase-input {
    &.Mui-disabled {
      -webkit-text-fill-color: var(--color-text-headings-secondary);
    }

    &::placeholder {
      font-family: var(--font-family-inter);
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-regular);
      line-height: var(--line-height-xl);
      color: var(--color-text-headings-secondary);
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-border-primary);
  }

  .multiline-input {
    .MuiInputBase-root {
      align-items: flex-start;
    }

    .MuiInputBase-input {
      overflow: auto;
      resize: none;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #eeeeee;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #bdbdbd;
        border-radius: 4px;
      }
    }
  }

  .rebrand-phase1 {
    .custom-text-input-focused .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-border-focused) !important;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-surface-action-primary-hover) !important;
    }

    &.MuiSelect-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-border-primary);

        &:hover {
          border-color: var(--color-surface-action-primary-hover) !important;
        }
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-border-focused) !important;
      }
    }

    .custom-text-input-root.MuiOutlinedInput-root {
      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-surface-action-primary-hover) !important;
      }
    }
  }
}

.country-dropdown-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* PhoneInput specific styles */

.country-code-text {
  color: var(--color-text-headings-secondary) !important;
  margin-left: 5px;
}

.phone-input-wrapper {
  width: 100%;
}

.form-control {
  width: 100%;
}

.input-label {
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-md);
  margin-bottom: 0.25rem;
  position: relative;
  transform: none;
}

.country-dropdown-text {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.country-dropdown-text .country-flag {
  margin-right: 5px;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  object-fit: cover !important;
}

.country-flag-container .country-flag {
  margin-right: 5px;
  border-radius: 50%;
  width: 24px !important;
  height: 24px !important;
  object-fit: cover !important;
}

.inverted-chevron-icon {
  transform: rotate(180deg) !important;
}

.country-code-arrow {
  font-size: 1.2em;
  margin-left: 4px;
  color: var(--color-primitives-black-black) !important;
}

.country-search-input {
  padding-inline: 16px;
  padding-block: 4px;
  margin-block: 0px !important;
}

.country-search-icon {
  color: #afafaf;
  margin-right: 10px;
}

.country-list {
  padding: 0;
}

.country-list-item {
  cursor: pointer;
  padding-inline: 16px;
  height: 36px !important;
}

.country-list-item:hover {
  background: #ededed;
}

.selected-country-item {
  background: #ededed !important;
}
.country-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.country-flag-container {
  margin-right: 8px;
}

.country-name {
  flex: 1;
}

.country-divider {
  margin-bottom: 8px;
}

.helper-text {
  margin-left: 0;
}

/* Adjustments for existing classes */
.ant-text-component .custom-text-input-root {
  min-width: 120px !important;
}

.ant-text-component .text-field-input-root .MuiInputBase-input {
  padding: 0px !important;
}

.ant-text-component .text-field-input-small {
  padding-block: 8px !important;
  height: 40px !important;
}

.ant-text-component .text-field-input-medium {
  padding-block: 16px !important;
}

.country-search-input {
  .MuiInputBase-input::placeholder {
    color: var(--color-text-headings-secondary) !important;
    opacity: 1;
  }
}

.countries-dropdown .MuiPaper-root {
  box-shadow: 0px 4px 8px 0px var(--color-elevation-default) !important;
  border-radius: 4px !important;
}

.no-result-phone-input {
  font-weight: var(--font-weight-regular) !important;
  font-size: var(--font-size-lg) !important;
  line-height: var(--line-height-xl) !important;
  padding-inline: 16px !important;
  padding-block: 6px !important;
}

.phone-input-country-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  display: block;
}

.auto-complete-paper {
  max-height: "max-content";
  overflow-x: scroll;
  width: max-content;
  min-width: 100%;
  overflow-y: hidden;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
  padding-right: 30px !important;
}

input[type="search"]::-webkit-search-cancel-button {
  filter: invert(80%) sepia(0%) saturate(0%) hue-rotate(134deg) brightness(92%) contrast(78%);
}
