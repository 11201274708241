@import "styles/Typography.scss";
@import "styles/Colors.scss";

.experience-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.no-experience-container {
  margin-top: 15px;
  display: flex;
  height: 72px;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--font-size-xs, 10px);
  align-self: stretch;
  border-radius: 8px;
}

.no-experience-container p {
  color: var(--color-text-body-primary);
}

.no-experience-other-admin p {
  color: var(--color-text-body-primary);
}

.no-experience-other-admin {
  margin-top: 15px;
  display: flex;
  height: 72px;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid var(--color-border-primary);
}

.no-experience-other-employee {
  margin-top: 15px;
}

.no-experience-other-customer {
  margin-top: 15px;
  display: flex;
  height: 72px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid var(--color-border-primary);
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 18px;
}

.custom-checkbox {
  color: var(--color-brand-primary-grey-600) !important;
}

.custom-label {
  .MuiFormControlLabel-label.Mui-disabled {
    color: var(--color-text-body-secondary) !important;
  }
}

.add-more-container {
  width: 16%;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 20px;
}

.add-more-icon {
  cursor: pointer;
}

.add-more-text {
  cursor: pointer;
  @extend .para-body-l-regular;
  color: var(--color-primitives-black-black);
  margin-top: 0 !important;
}

.save-experience-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
}

.field-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.add-more-button {
  margin-top: 10px;
}

.drag-icon {
  color: var(--color-brand-primary-grey-400);
  cursor: grab;
  margin-right: 12px;
  margin-left: -6px;
}

.delete-description-icon {
  color: var(--color-brand-primary-grey-400);
}

.timeline-more-icon {
  width: 24px;
  height: 24px;
  color: var(--color-icon-information-inverse);
}

.icon-wrapper {
  position: relative;
}

.paper-style {
  position: absolute;
  width: 160px;
  padding: 8px 0px;
  right: 5px;
  z-index: 1;
}

.edit-menu-items {
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}

.job-title {
  @extend .heading-h4-medium;
  color: var(--color-text-headings-primary);
}
.company-name {
  @extend .para-body-m-medium;
  color: var(--color-text-action);
  margin-top: 3px;
}

.experience-date {
  @extend .para-body-m-medium;
  color: var(--color-text-body-primary);
}

.modal-heading {
  @extend .heading-h2-semibold;
  color: var(--color-text-headings-primary);
}

.work-off-icon {
  width: 64px;
  height: 64px;
  color: var(--color-brand-primary-grey-400);
}

.checkbox-container {
  .MuiTypography-root {
    @extend .para-body-l-regular;
    color: var(--color-text-headings-primary);
  }
}

.drag-icon-container {
  margin-top: 7px;
  margin-right: -5px;
}

.add-other-btn {
  margin-top: -10px !important;
}

.add-icon {
  cursor: pointer;
  margin-right: -23px;
}
